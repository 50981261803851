import React, { useEffect, useState } from "react"
import SEO from "../seo"
import {
  AnyQuestions,
  background,
  Description,
  HowMuch,
  MainSection,
  SectionModel,
  surface,
  Warranty,
} from "./index"
import { URL_HOTMART_EBOOK_METAS } from "../../constants"
import { getQueryParam } from "../../utils/query"
import { getTextCupom, formatToMoney } from "../../utils/cupom"
const ebookMetas = require("../../assets/images/ebookMetas.png")

export default function Metas() {
  const [cupom, setCupom] = useState(null)
  const [utm, setUtm] = useState('')

  useEffect(() => {
    setCupom(getQueryParam("cupom"))
    setUtm(getQueryParam("utm_source"))
  }, [])

  function getUrlCheckout(){
    let url = URL_HOTMART_EBOOK_METAS
    if(cupom){
      url = url.concat("?offDiscount=" + cupom)
    }
    if(utm){
      const prefix = url.includes("?") ? "&": "?"
      url = url.concat(prefix+'sck='+utm)
    }
    return url
  }

  function getInfo() {
    const discontPercentage = cupom
      ? Number(cupom.replace(/\D/g, "") || "0")
      : 0
    const price = 34.7
    return {
      URL_CHECKOUT: getUrlCheckout(),
      oldPrice: "99,70",
      price: formatToMoney(price),
      newPrice: cupom
        ? formatToMoney(price * (1 - discontPercentage / 100))
        : formatToMoney(price),
      textCupom: cupom
        ? getTextCupom(cupom) ||
        (discontPercentage
          ? `${discontPercentage}% DE DESCONTO! APROVEITE! 🔥`
          : null)
        : null,
      discontPercentage,
    }
  }
  const {
    URL_CHECKOUT,
    price,
    newPrice,
    oldPrice,
    textCupom,
    discontPercentage,
  } = getInfo()

  const sendEvent = (event)=>{
    if(window){
      if(window.fbq) window.fbq('track', event);             
      if(window.gtag)window.gtag("event", event);
    }
  }

  return (
    <>
      <SEO title="Metas e Planejamentos" pageId="ebook_metas" />
      <MainSection
        img={ebookMetas}
        title="Está cansado de não alcançar suas metas e se sentir perdido?"
        description={
          <>
            Conheças as ferramentas que te ajudarão a estabelecer metas realistas e a criar planejamentos para alcançar estas metas de forma eficiente.
          </>
        }
        textCupom={textCupom}
      />
      <SectionModel
        id="more"
        background={surface}
        title="Isto é o que acontece quando você NÃO sabe criar metas e planejamentos eficientes"
        list={
          <Description >
            • Se sente perdido, sem direção em relação aos seus objetivos; <br /><br />
            • Define metas e planejamentos, mas nunca os segue;<br /><br />
            • Vê outras pessoas realizando sonhos e se sente “para trás” na vida;<br /><br />
            • Suas semana passam e você nunca realizou nada do que se propôs;<br /><br />
            • Procrastina constantemente;<br /><br />
            • Sente que seus sonhos são impossíveis.<br /><br /><br />
            Fala a verdade: você já se sentiu assim, não é?
          </Description>
        }
      />
      <SectionModel
        id="3"
        background={background}
        title="Para quem é o Guia sobre Metas e Planejamentos?"
        list={
          <Description >
            Nós criamos este guia para você que deseja:<br /><br /><br />
            ✔️ Criar metas significativas que realmente são cumpridas;<br /><br />
            ✔️ Desenvolver planejamentos que te ajudem a alcançar estas metas;<br /><br />
            ✔️ Manter-se motivado e alcançar seus objetivos com facilidade;<br /><br />
            ✔️ Ter uma rotina mais leve e organizada;<br /><br />
            ✔️ Driblar a procrastinação.<br /><br />
          </Description>
        }
      />
      <SectionModel
        id="5"
        background={surface}
        title={"O que você vai receber?"}
        img={ebookMetas}
        list={
          <Description >
            Adquira o guia agora mesmo e tenha acesso a:<br /><br /><br />
            ✔️ 6 passos essenciais para definir uma meta;<br /><br />
            ✔️ 6 tipos de planejamento que você deve incluir na sua vida;<br /><br />
            ✔️ 3 bônus exclusivos em PDF;<br /><br />
            ✔️ 50+ páginas de conteúdo e bônus.<br /><br />
          </Description>
        }
      />
      <SectionModel
        id="6"
        background={background}
        title={"O Guia sobre Metas e Planejamentos é um compilado de diversas técnicas e conhecimentos sobre metas e planejamentos"}
        list={
          <>
            <Description style={{ marginTop: "3rem" }}>
              Isso significa que, para que você tivesse acesso a todo esse conhecimento, você deveria comprar e ler diversos livros e fazer diversos cursos.<br /><br />

              Ou seja, o preço que você estará pagando por este ebook não é nada comparado ao valor que ele pode te trazer. <br /><br />

              Afinal, coisas como liberdade e qualidade de vida são coisas que o dinheiro não paga!<br /><br />

              Lembre-se: esse não é apenas um ebook, é a oportunidade de transformar sua vida, dominar suas metas e planejamentos e alcançar sua vida dos sonhos.<br /><br />

            </Description>

            <HowMuch
              discontPercentage={discontPercentage}
              oldPrice={oldPrice}
              price={price}
              newPrice={newPrice}
              urlCheckout={URL_CHECKOUT}
              onClick={()=> sendEvent('click_checkout_ebook_metas')}
            />
          </>
        }
      />

      <Warranty id="7" background={surface} />
      <AnyQuestions id="8" />
    </>
  )
}
